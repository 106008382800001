import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addQuantity,
  removeToCart,
  subQuantity,
} from "../../Services/Action/Action";
import { Link } from "react-router-dom";
import ReactHelmet from "../../Components/ReactHelmet/ReactHelmet";

const Cart = ({ user }) => {
  const getCarddata = useSelector((state) => state.cardItems);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [additionalFee, setAdditionalFee] = useState(0);

  useEffect(() => {
    if (getCarddata?.cardData) {
      let sub = 0;
      for (let i = 0; i < getCarddata.cardData.length; i++) {
        sub +=
          getCarddata.cardData[i].quantity *
          parseFloat(getCarddata.cardData[i].price);
      }
      setSubtotal(sub);
      if (sub < 49) {
        setAdditionalFee(20);
        setTotal(sub + 20);
      } else {
        setAdditionalFee(0);
        setTotal(sub);
      }
    }
  }, [getCarddata]);

  const dispatch = useDispatch();

  const removeHandler = (e, get) => {
    e.preventDefault();
    dispatch(removeToCart(get));
  };

  const addHandler = (e, get) => {
    e.preventDefault();
    dispatch(addQuantity(get));
  };

  const minusHandler = (e, get) => {
    e.preventDefault();
    dispatch(subQuantity(get));
  };

  const getBody = () => {
    if (getCarddata?.cardData?.length > 0) {
      return (
        <div className="bg-white !m-0 lg:px-32">
          <div className="container flex flex-col gap-8 !py-8">
            <div className="text-center text-p text-[26px] font-bold">
              <p>
                My <span className="text-green">Cart</span>
              </p>
            </div>

            <form className="">
              <section className="flex flex-col gap-2 w-full bg-white rounded-md shadow-lg overflow-hidden border">
                <div className="hidden md:flex flex-row w-full gap-4 font-bold text-base lg:text-xl text-left p-4 bg-zinc-100">
                  <div className="flex-[5] flex flex-row gap-4">
                    <span className="flex-[4]">Product</span>
                    <span className="flex-1">Unit Price</span>
                  </div>
                  <div className="flex flex-[2] flex-row gap-4 justify-between">
                    <span className="flex-1">Quantity</span>
                    <span className="flex-1">Amount</span>
                  </div>
                </div>
                {getCarddata.cardData.map((get, keys) => (
                  <div
                    className={`flex flex-col md:flex-row w-full gap-4 text-base text-left md:px-4 font-semibold ${!!keys ? "pt-4 md:py-4 md:border-t-[1px]" : "pt-4 md:py-2"}`}
                    key={keys}
                  >
                    <div className="flex-[5] flex flex-row gap-4 px-4 md:p-0">
                      <div className="flex-[4] flex flex-row gap-2">
                        <img
                          src={get.image}
                          alt={get.name}
                          className="w-[75px] lg:w-[100px] aspect-square rounded object-contain border-2 border-zinc-100"
                        />

                        <div className="flex flex-col gap-2 items-start justify-between">
                          <span className="text-base lg:text-xl">
                            {get.name}
                          </span>

                          <button
                            className="text-red-700 border-red-100 px-4 py-2 border-[1px] rounded hover:bg-red-100"
                            onClick={(e) => removeHandler(e, get)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>

                      <div className="flex-1 flex items-start md:items-center">
                        <span className="flex flex-row gap-1 text-slate-600 md:text-inherit font-semi-bold">
                          <span className="block md:hidden">Price:</span>$
                          {get.price.toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-[2] flex-row gap-4 justify-between bg-zinc-50 md:bg-inherit p-4 md:p-0 ">
                      <div className="flex w-fit md:flex-1 items-center justify-start">
                        <div className="flex flex-row font-bold border-[1px] border-slate-500 text-slate-500 select-none cursor-pointer *:px-2 *:py-1 rounded overflow-hidden">
                          <span
                            className="hover:bg-p hover:text-white -mt-[1px]"
                            onClick={(e) => minusHandler(e, get)}
                          >
                            -
                          </span>
                          <span>{get.quantity}</span>
                          <span
                            className="hover:bg-green hover:text-white -mt-[1px]"
                            onClick={(e) => addHandler(e, get)}
                          >
                            +
                          </span>
                        </div>
                      </div>

                      <div className="w-fit md:flex-1 flex items-center">
                        <span className="flex flex-row gap-1 font-semibold text-green">
                          <span className="block md:hidden">Amount:</span>$
                          {(get.price * get.quantity).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            </form>

            <section className="flex flex-col gap-6 py-4 w-full bg-white rounded-md shadow-lg border">
              <div className="flex flex-row w-full gap-4 font-semibold text-slate-500 text-base lg:text-lg text-left px-4">
                <span className="flex-[7]">Subtotal</span>
                <span className="flex-1 text-right md:text-left">
                  ${subtotal.toFixed(2)}
                </span>
              </div>

              <div className="flex flex-row w-full gap-4 font-semibold text-slate-500 text-base lg:text-lg text-left px-4">
                <span className="flex-[7]">
                  Shipping charge
                  <span className="font-semibold italic text-sm text-zinc-700">
                    {subtotal < 49
                      ? " (Get free shipping on orders above $49)"
                      : ""}
                  </span>
                </span>
                <span className="flex-1 text-right md:text-left">
                  ${additionalFee.toFixed(2)}
                </span>
              </div>

              <div className="flex flex-row w-full gap-4 font-bold text-xl lg:text-2xl text-left px-4">
                <span className="flex-[7]">Total</span>
                <span className="flex-1 text-right md:text-left text-p">
                  ${total.toFixed(2)}
                </span>
              </div>

              {!user && (
                <p className="italic px-4 text-sm font-normal text-zinc-700">
                  Create an account and get 10% off on your first order
                </p>
              )}

              <hr />

              <div className="checkout px-4 !m-0">
                <Link to={"/checkout"} className="button">
                  <button className="btn text-xl py-4 !m-0">Checkout</button>
                </Link>
              </div>
            </section>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bg-white !m-0">
          <div className="container flex flex-col gap-8 !py-8">
            <div className="text-center text-p text-[26px] font-bold">
              <p>
                My <span className="text-green">Cart</span>
              </p>
            </div>

            <section className="flex justify-center">
              <div className="flex flex-col gap-4 main-topic button [&_.btn]:!px-4 [&_.btn]:!m-0 border-2 py-4 px-12 rounded-md bg-white w-fit">
                <p className="text-lg">No product in your cart.</p>
                <p className="text-base text-zinc-600 font-normal">
                  Browse through our categories and add products to cart.
                </p>

                <Link to="/" className="font-normal">
                  <button className="btn !w-fit">Go Shopping</button>
                </Link>
              </div>
            </section>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <ReactHelmet title="Cart - Nepali Garden" />
      {getBody()}
    </div>
  );
};

export default Cart;
