import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Homerouting from "./HomeRouting/Homerouting";
import Footer from "./Components/Footer/Footer";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SuccessSnackbar from "./Components/BodyComponents/snackbar/snackbar";
import { SnackbarProvider } from "notistack";

import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

ReactGA.initialize(process.env.REACT_APP_GA_ID);

const theme = createTheme({
  palette: {
    primary: {
      main: "#B6101D",
    },
  },
});
function App() {
  const [user, setUser] = useState(null);

  const queryClient = new QueryClient();

  useEffect(() => {
    let data = localStorage.getItem("user");
    if (data) {
      setUser(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <SuccessSnackbar />
            <Navbar user={user} setUser={setUser} />
            <Homerouting user={user} setUser={setUser} />
            <Footer />
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
