import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useBannerListQuery = () => {
  return useQuery({
    queryKey: ["banner-list"],
    queryFn: async () => {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/carousel/get`,
      });

      let response = res.data.data;

      return response;
    },
    refetchOnWindowFocus: false,
  });
};
