import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeletoncard from "../skeletoncard/skeletoncard";
import { addToCart } from "../../../Services/Action/Action";
// import { addToWishlist } from "../../../Services/Action/WishlistAction";
import { useSnackbar } from "notistack";
import { useOfferedProductListQuery } from "../../../queries/product.queries";

const OfferProducts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handler = (e, product) => {
    dispatch(addToCart(product));
    enqueueSnackbar("Item added to cart", {
      variant: "success",
    });
  };

  // const handlerWish = (e, product) => {
  //   e.preventDefault();
  //   dispatch(addToWishlist(product));
  //   enqueueSnackbar("Item added to wishlist", {
  //     variant: "success",
  //   });
  // };

  const { data: products, isLoading } = useOfferedProductListQuery();

  if (isLoading) {
    return (
      <div className="container mt-4 sm:mt-8 grid grid-cols-2 min-[700px]:grid-cols-4 xl:grid-cols-5 gap-4">
        <Skeletoncard />
      </div>
    );
  } else if (products.length === 0) {
    return null;
  }

  return (
    <div className="feature-main pb-8 sm:pb-16 bg-white">
      <div className="container">
        {products.length > 0 && (
          <div className="text-center text-p text-[26px] font-bold">
            <p>
              Featured <span className="text-green">Products</span>
            </p>
          </div>
        )}
        <div className="mt-4 grid grid-cols-2 min-[700px]:grid-cols-4 xl:grid-cols-5 sm:gap-4 sm:mt-8">
          {products.slice(0, 19).map((product, id) => {
            return (
              <div
                className="group sm:shadow-md border sm:border-[#f6f7f8] bg-white sm:rounded duration-300 hover:bg-t flex flex-col gap-4"
                key={id}
              >
                <div className="w-full aspect-square relative overflow-hidden px-1 pt-1 sm:px-2 sm:pt-2">
                  <NavLink to={`/product/${product.slug}`}>
                    <img
                      src={product.image}
                      alt="featureproducts"
                      className="border border-stone-50 rounded-sm h-full w-full object-cover"
                      loading="lazy"
                    />
                  </NavLink>
                </div>

                <NavLink
                  to={`/product/${product.slug}`}
                  className="flex-1 text-center px-2"
                >
                  <p className="text-sm xs:text-base font-medium text-ellipsis line-clamp-2 text-black group-hover:text-p duration-300">
                    {product.name}
                  </p>
                </NavLink>

                <p className="cart-content-price text-base font-extrabold text-p text-center px-2">
                  $ {product.price.toFixed(2)}
                </p>

                {Number(product.in_stock) === 1 ? (
                  <div className="rounded bg-green transition-colors duration-500 hover:bg-[#15632f] mx-2 mb-2">
                    <button
                      type="button"
                      className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm"
                      onClick={(e) => handler(e, product)}
                    >
                      <i className="fas fa-shopping-cart pe-1"></i>
                      ADD TO CART
                    </button>
                  </div>
                ) : (
                  <div className="rounded bg-[#8c1818] mx-2 mb-2">
                    <div className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm">
                      Out of Stock
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OfferProducts;
