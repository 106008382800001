import React from "react";
import { useInView } from "react-intersection-observer";
import FeaturedProductGrid from "./FeaturedProductGrid";

const FeaturedProducts = () => {
  // const handlerWish = (e, product) => {
  //   e.preventDefault();
  //   dispatch(addToWishlist(product));
  //   enqueueSnackbar("Item added to wishlist", {
  //     variant: "success",
  //   });
  // };

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="feature-main pb-8 sm:pb-16">
      <div className="container">
        <div ref={ref} className="text-center text-p text-[26px] font-bold">
          <p>
            Our <span className="text-green">Products</span>
          </p>
        </div>

        {inView && <FeaturedProductGrid />}
      </div>
    </div>
  );
};

export default FeaturedProducts;
