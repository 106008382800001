import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const useFeaturedProductListQuery = () => {
  return useQuery({
    queryKey: ["product-list"],
    queryFn: async () => {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/product/getFeature`,
      });

      let response = res.data.products.data;

      return response;
    },
    refetchOnWindowFocus: false,
  });
};

export const useOfferedProductListQuery = () => {
  return useQuery({
    queryKey: ["offered-product-list"],
    queryFn: async () => {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/product/getOffer`,
      });

      let response = res.data.products.data;

      return response;
    },
    refetchOnWindowFocus: false,
  });
};
