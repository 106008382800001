import { NavLink } from "react-router-dom";
import { addToCart } from "../../../Services/Action/Action";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useInView } from "react-intersection-observer";
import Skeletoncard from "../skeletoncard/skeletoncard";
import { Skeleton, Stack } from "@mui/material";
import { useFeaturedProductListQuery } from "../../../queries/product.queries";

const FeaturedProductGrid = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { data: products, isLoading } = useFeaturedProductListQuery();

  const handler = (e, product) => {
    dispatch(addToCart(product));
    enqueueSnackbar("Item added to cart", {
      variant: "success",
    });
  };


  if (isLoading) {
    return (
      <div className="mt-4 sm:mt-8 grid grid-cols-2 min-[700px]:grid-cols-4 xl:grid-cols-5 gap-4">
        <Skeletoncard />
      </div>
    );
  }

  return (
    <section>
      <div className="mt-4 grid grid-cols-2 min-[700px]:grid-cols-4 xl:grid-cols-5 sm:gap-4 sm:mt-8">
        {products.slice(0, 30).map((product, id) => {
          return <GridItem key={id} product={product} handler={handler} />;
        })}
      </div>
      <div className="flex w-full items-center justify-center">
        <NavLink to="/filter">
          <button className="h-10 mt-12 px-6 text-white bg-green border border-green hover:border-hoverGreen hover:bg-hoverGreen transition-colors rounded-md font-bold">
            View All
          </button>
        </NavLink>
      </div>
    </section>
  );
};

function GridItem({ product, id, handler }) {
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className="group bg-white sm:shadow-md duration-300 hover:bg-t border sm:border-zinc-200 sm:rounded flex flex-col gap-4"
    >
      {!inView ? (
        <div>
          <Stack spacing={1}>
            <Skeleton variant="rectangular" width={"100%"} height={118} />

            <Skeleton variant="text" width={"80%"} />
            <Skeleton variant="text" width={"80%"} />
            <Skeleton variant="text" width={80} />
          </Stack>
        </div>
      ) : (
        <>
          <div className="w-full aspect-square relative overflow-hidden px-1 pt-1 sm:px-2 sm:pt-2">
            <NavLink to={`/product/${product.slug}`}>
              <img
                src={product.image}
                alt="featureproducts"
                className="border border-stone-50 rounded-sm h-full w-full object-cover"
                loading="lazy"
              />
            </NavLink>
          </div>

          <NavLink to={`/product/${product.slug}`} className={"flex-1 px-2"}>
            <p className="text-sm xs:text-base font-medium text-ellipsis line-clamp-2 text-black group-hover:text-p duration-300 text-center">
              {product.name}
            </p>
          </NavLink>

          <p className="cart-content-price text-base font-extrabold text-p text-center px-">
            $ {product.price.toFixed(2)}
          </p>

          {Number(product.in_stock) === 1 ? (
            <div className="rounded bg-green transition-colors duration-500 hover:bg-[#15632f] mx-2 mb-2">
              <button
                type="button"
                className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm"
                onClick={(e) => handler(e, product)}
              >
                <i className="fas fa-shopping-cart pe-1"></i>
                ADD TO CART
              </button>
            </div>
          ) : (
            <div className="rounded bg-[#8c1818] mx-2 mb-2">
              <div className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm">
                Out of Stock
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <div key={id}>
      <div className="w-full aspect-square relative overflow-hidden px-1 pt-1 sm:px-2 sm:pt-2">
        <NavLink to={`/product/${product.slug}`}>
          <img
            src={product.image}
            alt="featureproducts"
            className="border border-stone-50 rounded-sm h-full w-full object-cover"
            loading="lazy"
          />
        </NavLink>
      </div>

      <NavLink to={`/product/${product.slug}`} className={"flex-1 px-2"}>
        <p className="text-sm xs:text-base font-medium text-ellipsis line-clamp-2 text-black group-hover:text-p duration-300 text-center">
          {product.name}
        </p>
      </NavLink>

      <p className="cart-content-price text-base font-extrabold text-p text-center px-">
        $ {product.price.toFixed(2)}
      </p>

      {Number(product.in_stock) === 1 ? (
        <div className="rounded bg-green transition-colors duration-500 hover:bg-[#15632f] mx-2 mb-2">
          <button
            type="button"
            className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm"
            onClick={(e) => handler(e, product)}
          >
            <i className="fas fa-shopping-cart pe-1"></i>
            ADD TO CART
          </button>
        </div>
      ) : (
        <div className="rounded bg-[#8c1818] mx-2 mb-2">
          <div className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm">
            Out of Stock
          </div>
        </div>
      )}
    </div>
  );
}

export default FeaturedProductGrid;
